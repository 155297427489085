<template>
    <div>
        <Loading v-show="isLoading" />

        <div v-show="!isLoading">

            <!-- Toolbar -->    
            <TitlePage 
                title="NUEVO USUARIO"
                back="/users" 
                :reset="true"
            />

            <div class="col-12 text-right">
                <Button label="Generar Clave" icon="fas fa-key" size="small" severity="secondary" outlined @click="generatePwd()" />
            </div>

            <!-- Form --> 
            <div class="col-12 card mt-3">
                <form @submit.prevent="storeUser">
                    <div class="formgrid grid">

                        <div class="field col-12 md:col-6 lg:col-6 mt-3">
                            <span class="p-float-label">
                                <InputText 
                                    type="text" 
                                    id="user_last_name" 
                                    ref="user_last_name" 
                                    v-model="user.last_name" 
                                    autofocus tabindex="1" 
                                    class="inputfield w-full"
                                    @change="makeUsername()"
                                    autocomplete="off"
                                />
                                <label for="user_last_name">Apellido <sup>*</sup></label>
                            </span>
                        </div>

                        <div class="field col-12 md:col-6 lg:col-6 mt-3">
                            <span class="p-float-label">
                                <InputText 
                                    type="text" 
                                    id="user_first_name" 
                                    ref="user_first_name" 
                                    v-model="user.first_name" 
                                    tabindex="2" 
                                    class="inputfield w-full"
                                    @change="makeUsername()"
                                    autocomplete="off"
                                />
                                <label for="user_first_name">Nombre <sup>*</sup></label>
                            </span>
                        </div>

                        <div class="field col-12 md:col-3 lg:col-3 mt-3">
                            <span class="p-float-label">
                                <InputText 
                                    type="text" 
                                    id="user_phone" 
                                    ref="user_phone" 
                                    v-model="user.phone_number" 
                                    tabindex="3" 
                                    class="inputfield w-full"
                                    autocomplete="off"
                                />
                                <label for="user_phone">Teléfono</label>
                            </span>
                        </div>

                        <div class="field col-12 md:col-3 lg:col-3 mt-3">
                            <span class="p-float-label">
                                <InputText 
                                    type="text" 
                                    id="user_email" 
                                    ref="user_email" 
                                    v-model="user.email" 
                                    tabindex="4" 
                                    class="inputfield w-full"
                                    autocomplete="off"
                                />
                                <label for="user_email">Email <sup>*</sup></label>
                            </span>
                        </div>

                        <div class="field col-12 md:col-6 lg:col-6 mt-3">
                            <span class="p-float-label">
                                <InputText 
                                    type="text" 
                                    id="user_address" 
                                    ref="user_address" 
                                    v-model="user.address" 
                                    tabindex="5" 
                                    class="inputfield w-full"
                                    autocomplete="off"
                                />
                                <label for="user_address">Dirección</label>
                            </span>
                        </div>

                        <div class="field col-12 md:col-3 lg:col-3 mt-3">
                            <span class="p-float-label">
                                <InputText 
                                    type="text" 
                                    id="user_username" 
                                    ref="user_username" 
                                    v-model="user.username" 
                                    tabindex="6" 
                                    class="inputfield w-full"
                                    autocomplete="off"
                                />
                                <label for="user_username">Usuario <sup>*</sup></label>
                            </span>
                        </div>

                        <div class="field col-12 md:col-3 lg:col-3 mt-3">
                            <span class="p-float-label">
                                    <InputText 
                                        type="password" 
                                        id="user_password" 
                                        ref="user_password" 
                                        v-model="user.password" 
                                        tabindex="6" 
                                        class="inputfield w-full"
                                        autocomplete="off"
                                    />
                                <label for="user_password">Clave <sup>*</sup></label>
                            </span>
                        </div>

                        <div class="field col-12 md:col-3 lg:col-3 mt-3">
                            <span class="p-float-label">
                                <InputText 
                                    type="password" 
                                    id="user_password_confirmation" 
                                    ref="user_password_confirmation" 
                                    v-model="user.password_confirmation" 
                                    tabindex="6" 
                                    class="inputfield w-full"
                                    autocomplete="off"
                                />
                                <label for="user_password_confirmation">Repetir Clave <sup>*</sup></label>
                            </span>
                        </div>

                        <div class="field col-12 md:col-3 lg:col-3 mt-3">
                            <Dropdown 
                                id="user_state" 
                                ref="user_state" 
                                v-model="user.state" 
                                :options="defaultData.comboStates" 
                                optionLabel="label" 
                                optionValue="value"
                                placeholder="Estado" 
                                class="w-full"
                                tabindex="7"
                            />
                        </div>

                        <div class="field col-12 pt-3">
                            <div class="p-inputgroup">
                                <MultiSelect 
                                    id="user_role" 
                                    ref="user_role" 
                                    v-model="user.roles" 
                                    :options="roles" 
                                    optionLabel="name" 
                                    optionValue="id"
                                    placeholder="Seleccionar Roles *" 
                                    class="w-full"
                                    display="chip"
                                    tabindex="8"
                                />
                            </div>
                        </div>
                
                        <div class="col-12 my-5">
                            <span class="text-bluegray-300">Campos requeridos *</span>
                            <br>
                            <br>
                            <p class="text-blue text-lg mt-2" v-show="pwd != ''">Clave Generada: <b>{{ pwd }}</b></p>
                        </div>
                    </div>
                
                    <div class="grid">
                        <div class="col-12 text-center">
                            <Button
                                label="CARGAR"
                                tabindex="9"
                                type="submit"
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<style>
    .p-inputgroup{
        background-color: red !important;
    }
</style>

<script>
    import apiService from '../../services/apiService.js'

    export default {
        name: 'NewUser',

        data(){
            return {
                isLoading: false,
                user: {
                    username: '',
                    password: '',
                    password_confirmation: '',
                    last_name: '',
                    first_name: '',
                    phone_number: '',
                    email: '',
                    address: '',
                    state: 1,
                    roles: []
                },
                roles: [],
                pwd: '',
            }
        },
        mounted() {
            this.getRoles()
        },
        methods: {
            async getRoles(){
                apiService.Role.get()
                .then((result) => {
                    this.roles = result;                
                    this.isLoading = false
                })
            },
            async storeUser(){
                var form = this.globalMethods.getFormData(this.user);
                this.isLoading = true
                apiService.User.store(form)
                .then((result) => {
                    if(result.status == 'success'){
                        this.FormSuccess(result.message)
                        this.isLoading = false
                        this.resetForm();
                    }else{
                        this.FormErrors(result)
                        this.isLoading = false
                    }
                }).catch((e) => {
                    this.FormRequestFail(e);
                    this.isLoading = false
                })
            },
            async generatePwd(){
                this.pwd = Math.random().toString(36).slice(-8);
                this.user.password = this.pwd;
                this.user.password_confirmation = this.pwd;
            },
            resetForm(){
                this.user = {
                    username: '',
                    password: '',
                    password_confirmation: '',
                    last_name: '',
                    first_name: '',
                    phone_number: '',
                    email: '',
                    address: '',
                    state: 1,
                    roles: []
                }

                this.pwd = ''
            },
            makeUsername(){
                let a = this.user.last_name.trim().toLowerCase();
                let b = this.user.first_name.trim().toLowerCase();

                if(a != '' && b != ''){
                    this.user.username = `${a}.${b}`
                }
            }
        }
    }
</script>